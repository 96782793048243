import { module } from 'modujs';
import Typed from 'typed.js';

export default class extends module {
    constructor(m) {
        super(m);
      
    }

    init() {
        this.$el = this.el;
        // this.$num = this.$('num')[0]
        // var t = this;
        const $span = document.querySelector('.c-preloader__num-span')

        const option = {
            strings: ['for (int height = 0;<br>height `<` userInput; height++) {<br>for (int i = 0; i `<` userInput - 1; i++) {<br>row += "♡";<br>}'],
            typeSpeed: 10,
        }

        this.$typed = new Typed('.c-preloader__text', option);


        var e = 0;
        setTimeout(() => {
          this.interval = setInterval(function() {
            $span.innerText = e;
            e < 100 ? e++ : clearInterval(this.interval);
          }, 15);
        }, 450);
      }
}