import modular from "modujs";
import * as modules from "./modules";
import globals from "./globals";
import { html } from "./utils/environment";
import "./style.scss";
// import { gsap } from "gsap";

const app = new modular({
  modules: modules,
});

window.onload = (event) => {
  // document
  //   .querySelector(
  //     '[src="https://enchanting-cassata-e2a27c.netlify.app/dist/main.js"]'
  //   )
  //   .remove();
  // document
  //   .querySelector(
  //     '[href="https://enchanting-cassata-e2a27c.netlify.app/dist/main.css"]'
  //   )
  //   .remove();
  const $style = document.getElementById("main-css");

  if ($style) {
    if ($style.isLoaded) {
      init();
    } else {
      $style.addEventListener("load", (event) => {
        init();
      });
    }
  } else {
    console.warn('The "main-css" stylesheet not found');
  }
};

function init() {
  sessionStorage.setItem("firstLoad", "true");
  app.init(app);

  // console.log(
  //   "%c%s",
  //   "padding: 6px; color: #fff !important; background: crimson;",
  //   `/ Made by Beta /`
  // );
  // console.log("www.beta.ru");

  globals();

  setTimeout(() => {
    html.classList.add("is-first-loaded");
  }, 50);

  setTimeout(() => {
    html.classList.add("is-loaded");
    html.classList.add("is-ready");
    html.classList.remove("is-loading");
    setTimeout(() => {
      document.querySelector(".c-preloader").remove();
    }, 500);
  }, 2700);
}
