import { module } from 'modujs';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

// const MEDIA = 991
// const MEDIAUSER = window.screen.width

export default class extends module {
    constructor(m) {
        super(m);


  

        this.$el = this.el;    

        // this.onResizeBind = this.onResize.bind(this); // Добавьте эту строку

       
    }

    init() {
      let mm = gsap.matchMedia();
      mm.add("(min-width: 991px)", () => {
          this.headerAnim();
      })
      
    }

    // bindEvents() {
    //     window.addEventListener("resize", this.onResizeBind);
    // }

    // onResize() {
    //     this.headerAnim();
    // }

    // unbindEvents() {
    //     window.removeEventListener("resize", this.onResizeBind);
    // }

    headerAnim(){

        // ScrollTrigger.saveStyles('.c-header-anim__logo, .c-logo-span-1, .c-logo-span-2, .c-hero-anim__logo-inner, .c-header-anim__container');

      

    let tlEnter = gsap.timeline({
        scrollTrigger:{
          trigger: '.c-hero-anim',
          start: "top top",
          end: "bottom bottom",
          scrub: true,
          invalidateOnRefresh: true,
          // markers: true,
        },})

        tlEnter.to(".c-header-anim__logo", { 
            height: 0,
            width: 0,
            ease: "ease",
            duration: 10,
          }, "<0");

        tlEnter.to(".c-hero-anim__logo-inner", { 
            scale: 0.12,
            ease: "ease",
            y: '0',
            duration: 6,
          }, '<0');



        tlEnter.to(".c-logo-span-1, .c-logo-span-2", { 
          opacity: 0,
          ease: "ease",
          duration: 2,
        }, '<0.4');

        tlEnter.to(".c-logo-span-1, .c-logo-span-2", { 
          width: 0,
          ease: "ease",
          duration: 2,
        }, '<1');



        
        tlEnter.to(".c-header-anim__container", { 
            height: '0vh',
            ease: "ease",
            duration: 10,
          });
     
    
}

destroy(){
    super.destroy()
    // this.unbindEvents()
}
    
}




