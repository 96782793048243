
import { module } from "modujs";
import { html } from "../utils/environment";

export default class extends module {
  constructor(m) {
    super(m);
  }
  

  onScrollProgress(progress) {
    this.$progress = `${Math.round((progress + Number.EPSILON) * 100 )/100 }`
    // console.log(this.$progress);
    html.style.setProperty('--header-progress', this.$progress);
    // html.style =''
}
}
