import { module } from 'modujs';

const PIN = {
  LARGE: `https://uploads-ssl.webflow.com/6499577d6d223523b3820988/65141b32f7bbf8c35079b603_pin-rolftech-black.svg`,
  // SMALL: `https://uploads-ssl.webflow.com/6499577d6d223523b3820988/65141d22265d9bb831108de7_pin-rolftech-small-black.svg`,
}


export default class extends module {
    constructor(m) {
        super(m)

        this.$map = this.$('map')[0]
        this.$el = this.el


        this.events ={
          click: {
            'active': 'activeMap',
          }
        }
    }

init(){
   const ymaps = window.ymaps;
  ymaps.ready(()=>{
    this.map()
  });
}

    map(){
          let center = [55.862631, 37.582711];

          let pin = PIN.LARGE
          let pinSize =  [133, 46];
            
          let map = new ymaps.Map('mapContainer', {
            center: center,
            zoom: 15
          });


          // if(window.innerWidth < 991){
          //   pin = PIN.SMALL
          //   pinSize = [60, 82]
          // }

          let placemark = new ymaps.Placemark(center, {}, {
            iconLayout: 'default#image',
            iconImageHref: pin,
            iconImageSize: pinSize,
            iconImageOffset: [-19, -44]
          });

          map.controls.remove('geolocationControl'); // удаляем геолокацию
          map.controls.remove('searchControl'); // удаляем поиск
          map.controls.remove('trafficControl'); // удаляем контроль трафика
          map.controls.remove('typeSelector'); // удаляем тип
          map.controls.remove('fullscreenControl'); // удаляем кнопку перехода в полноэкранный режим
          map.controls.remove('zoomControl'); // удаляем контрол зуммирования
          map.controls.remove('rulerControl'); // удаляем контрол правил
          map.behaviors.disable(['scrollZoom']); // отключаем скролл карты (опционально)

          map.geoObjects.add(placemark);

      }

    activeMap() {
        this.$el.classList.add("is-active")
    }  

}