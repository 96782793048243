import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m)
        this.events ={
          mouseenter: {
            toggle: 'menuOpen'
          },
          mouseleave: {
            toggle: 'menuClose'
          },
        }
    }


    menuOpen(){ 
        html.classList.add('menu-active')
      }
      menuClose(){ 
        html.classList.remove('menu-active')
      }
}


