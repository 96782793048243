import { module } from 'modujs';
import modularLoad from 'modularload';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m)
    }

    init() {
        this.load = new modularLoad({
            enterDelay: 700,
            transitions: {
                hero: {},
                vacancies: {},
                contacts: {},
                stack: {},
                team: {},
                coop: {},
                live: {},
                about: {},
                project: {},

            }
        })
        
        this.load.on('loading', (transition, oldContainer) => {
            window.Webflow.destroy()
            window.Webflow.require('ix2').destroy()
            window.Webflow.require('lottie').destroy()
            html.classList.remove('menu-active')
            this.scrollPosition()
            sessionStorage.setItem('firstLoad', false)
        })

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            this.call('destroy', oldContainer, 'app');
            this.call('update', newContainer, 'app');
            
            html.classList.remove('menu-active')

            if (transition == 'hero') {
                const e = document.getElementById('hero');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }

            if (transition == 'project') {
                const e = document.getElementById('products');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'coop') {
                const e = document.getElementById('cooperation');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'live') {
                const e = document.getElementById('live');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'about') {
                const e = document.getElementById('about-us');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'contacts') {
                const e = document.getElementById('contacts');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'stack') {
                const e = document.getElementById('about-us');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'team') {
                const e = document.getElementById('team');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
            if (transition == 'vacancies') {
                const e = document.getElementById('vacancies');
                this.call('scrollTo', { target: e, options: { duration: 0 } }, 'Scroll')
            }
        })

        this.load.on('loaded', (transition, oldContainer, newContainer) => {
            window.Webflow.ready()
            window.Webflow.require('ix2').init()
            window.Webflow.require('lottie').init()
        })
    }

    scrollPosition(){
        const e = sessionStorage.setItem("scroll", window.scrollY)
        sessionStorage.setItem("back", sessionStorage.getItem('scroll'))
    }



    goTo(e) {
        this.load.goTo(e.url, e.transition);
    }
}
