import { module } from 'modujs';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/all';
import { html } from '../utils/environment';

gsap.registerPlugin(ScrollTrigger)

export default class extends module {
    constructor(m) {
        super(m)
        this.events ={
          click: {
            back: 'goBack',
          }
        }

        this.$back = this.$('back')[0]
        
    }

     goBack() {
      window.history.back();
    }


    init() {


      const section = document.querySelector("[data-section='project']")
      const section2 = document.querySelector("[data-section='partner']")
      const section3 = document.querySelector("[data-section='live']")
      const section4 = document.querySelector("[data-section='vacancies']")
      const section5 = document.querySelector("[data-section='project']")
      const section6 = document.querySelector("[data-section='contacts']")



gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section,
      start: "top top",
      end: "bottom bottom",
      onEnter: ()=>{
          html.classList.add('is-project')
      },
      onLeaveBack: ()=>{
        html.classList.remove('is-project')
      },
      // markers: true,
    }
  });
gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section2,
      start: "top top",
      onEnter: ()=>{
        html.classList.add('is-partner')
    },
    onLeaveBack: ()=>{
      html.classList.remove('is-partner')
    },
      end: "bottom bottom",
      // markers: true,
    }
  });
gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section3,
      start: "top top",
      onEnter: ()=>{
        html.classList.add('is-live')
    },
    onLeaveBack: ()=>{
      html.classList.remove('is-live')
    },
      end: "bottom bottom",
      // markers: true,
    }
  });
gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section4,
      start: "top top",
      onEnter: ()=>{
        html.classList.add('is-vacancies')
    },
    onLeaveBack: ()=>{
      html.classList.remove('is-vacancies')
    },
      end: "bottom bottom",
      // markers: true,
    }
  });
gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section5,
      start: "top top",
      onEnter: ()=>{
        html.classList.add('is-project')
    },
    onLeaveBack: ()=>{
      html.classList.remove('is-project')
  },
      end: "bottom bottom",
      // markers: true,
    }
  });
gsap.to(".c-header__list", {
    scrollTrigger: {
      trigger: section6,
      start: "top top",
      onEnter: ()=>{
        html.classList.add('is-contacts')
    },
    onLeaveBack: ()=>{
      html.classList.remove('is-contacts')
  },
      end: "bottom bottom",
      // markers: true,
    }
  });
    }

}
