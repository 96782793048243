import { module } from 'modujs';
import { rect } from '../utils/dom';

export default class extends module {
    constructor(m) {
        super(m);
    this.$el = this.el;
    this.$parent = this.$el.parentElement;
    this.$svg = this.$('svg')[0].querySelector('svg');
    this.$rect = this.$('svg')[0].querySelector('rect');

    this._resize = this._resize.bind(this);

    // Binding
    this.onResizeBind = this.onResize.bind(this);
  }

  init() {
    this.bindEvents()
    // this._bindEvents();
    this._resize() 
  }

  // destroy() {
  //   this._unbindEvents();

  //   this.el = null;
  //   this.emitter = null;
  //   this.$rects = null;

  //   this._ro = null;
  // }

//   _bindEvents() {
//     if(this.$rects.length) ResizeOrientation.add(this.$resize);
//   }

//   _unbindEvents() {
//     if(this.$rects.length) ResizeOrientation.remove(this.$resize);
//   }

bindEvents() {
  window.addEventListener("resize", this.onResizeBind);
}

onResize() {
  this._resize() 
}

unbindEvents() {
  window.removeEventListener("resize", this.onResizeBind);
}


  _resize() {
    let { width, height } = rect(this.$el);

    // round dimensions values
    width = parseInt(width);
    height = parseInt(height);

    // transform SVG viewBox and dimension
    this.$svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
    this.$svg.setAttribute("width", width);
    this.$svg.setAttribute("height", height);
    // this.$el.style.setProperty('width', `${width}px`);
    // this.$el.style.setProperty('height', `${height}px`);

    // transform each <rect/>

        const offset = this.$rect.getAttribute("stroke-width");
        const scale = this.$rect.getAttribute('vector-effect') === 'non-scaling-stroke' ? rect(this.$rect).width / this.$rect.getBBox().width : 1;
        this.$rect.setAttribute("width", width - offset);
        this.$rect.setAttribute("height", height - offset);
        this.$rect.setAttribute("x", offset / 2);
        this.$rect.setAttribute("y", offset / 2);
        this.$rect.style.setProperty('--scale', scale);
        this.$rect.style.setProperty('--length', `${this.$rect.getTotalLength()}px`);
        this.$rect.style.setProperty('--height', `${height - offset}px`);
        this.$rect.style.setProperty('--width', `${width - offset}px`);

  }

destroy() {
  super.destroy()
  this.unbindEvents()
}
}