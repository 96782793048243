import { module } from 'modujs';
// import gsap from 'gsap';
// import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

export default class extends module {
    constructor(m) {
        super(m);


  

        this.$el = this.el;    
       
    }
    init(){


      let tlEnter = gsap.timeline({
        scrollTrigger:{
          trigger: this.$el,
          start: "top top",
          end: "120% bottom",
          scrub: true,
          // markers: true,
        },})
         
        tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
          scale: 1,
          opacity: 1,
          ease: "ease",
          duration: 10,
        });
        // tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
        //   scale: 1,
        //   // y: '-20vh',
        //   ease: "ease",
        //   // delay: 1,
        //   duration: 2,  
        // });
        tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
          scale: 1,
          ease: "ease",
          // delay: 1,
          duration: 20,
        });
        tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
          scale: 0.1,
          y: '-30rem',
          opacity: 0,
          ease: "ease",
          // delay: 1,
          duration: 20,
        });
        // tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
        //   // scale: 0.1,
        //   y: '-60vh',
        //   opacity: 0,
        //   ease: "ease",
        //   // delay: 1,
        //   duration: 2,
        // });
        tlEnter.to(".c-live__span", { // Первая анимация при onEnter
          y: 0,
          scale: 1,
          opacity: 1,
          ease: "ease",
          duration: 10,  
          // delay: 1,
          stagger: 1
        }, "<4");
        tlEnter.to(".c-live__span", { // Первая анимация при onEnter
          duration: 10,  
        });
        tlEnter.to(".c-live__span", { // Вторая анимация при onLeave
          y: '-60vh',
          opacity: 0,
          scale: 0.5,
          ease: "ease",
          duration: 10,
          stagger: 1
        });

        // let tlEnter = gsap.timeline({
        //     scrollTrigger:{
        //       trigger: this.$el,
        //       start: "25% center",
        //       end: "bottom center",
        //       markers: true,
        //       onEnter: () => {
        //         tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
        //           scale: 1,
        //           opacity: 1,
        //           ease: "ease",
        //           duration: 0.4,
        //         }, 0.1);
        //         tlEnter.to(".c-live__rolftech-text", { // Первая анимация при onEnter
        //           scale: 1,
        //           opacity: 0,
        //           ease: "ease",
        //           delay: 1,
        //           duration: 0.4,
        //         }, 0.1);
        //         tlEnter.to(".c-live__span", { // Первая анимация при onEnter
        //           y: 0,
        //           scale: 1,
        //           opacity: 1,
        //           ease: "ease",
        //           duration: 0.6,
        //           delay: 0.6,
        //           stagger: 0.1
        //         });
        //       },
        //       onLeave: () => {
        //         tlLeave.to(".c-live__span", { // Вторая анимация при onLeave
        //           y: '-20vh',
        //           opacity: 0,
        //           scale: 0.5,
        //           ease: "ease",
        //           duration: 0.6,
        //           stagger: 0.1
        //         });
        //       },

        //       onLeaveBack: () => {
        //         tlEnter.to(".c-live__span", { // Вторая анимация при onLeave
        //           y: '20vh',
        //           opacity: 0,
        //           scale: 0.5,
        //           ease: "ease",
        //           duration: 0.6,
        //           stagger: -0.1
        //         });
        //       },
        //       onEnterBack: () => {
        //         tlEnter.to(".c-live__span", { // Вторая анимация при onLeave
        //           y: 0,
        //           opacity: 1,
        //           scale: 1,
        //           ease: "ease",
        //           duration: 0.6,
        //           stagger: -0.1
        //         });
        //       }
        //     }
        //   })
          
        //   let tlLeave = gsap.timeline(); // Создаем пустую анимацию для onLeave

          // let tlRolf = gsap.timeline({
          //   scrollTrigger:{
          //     trigger: this.$el,
          //     start: "20% center",
          //     end: "45% center",
          //     // markers: true,
          //     onEnter: () => {
          //       tlRolf.to(".c-live__rolftech-text", { // Первая анимация при onEnter
          //         scale: 1,
          //         opacity: 1,
          //         ease: "ease",
          //         duration: 0.4,
          //       });
          //     },
          //     onLeave: () => {
          //       tlRolf.to(".c-live__rolftech-text", { // Вторая анимация при onLeave
          //         opacity: 0,
          //         scale: 0.1,
          //         ease: "ease",
          //         duration: 0.4,
          //       });
          //     },

          //     onLeaveBack: () => {
          //       tlRolf.to(".c-live__rolftech-text", { // Вторая анимация при onLeave
          //         opacity: 0,
          //         scale: 0.1,
          //         ease: "ease",
          //         duration: 0.4,
          //       });
          //     },
          //     onEnterBack: () => {
          //       tlRolf.to(".c-live__rolftech-text", { // Вторая анимация при onLeave
          //         opacity: 1,
          //         scale: 1,
          //         ease: "ease",
          //         duration: 0.4,
          //       });
          //     }
            
          //   }
          // })


    }
    
}




