import { module } from 'modujs';


export default class extends module {
    constructor(m) {
        super(m)
        this.$el = this.el
    }
    
    init(){
        const firstCheck = sessionStorage.getItem('firstLoad')
    this.$el.addEventListener('click', ()=>{
    if(firstCheck !== 'true'){
        window.history.back()
        let getPosition = sessionStorage.getItem("scroll")
        setTimeout(()=>{
            // this.call('scrollTo' , {target: getPosition}, "Scroll")
            window.scrollTo(0, getPosition)
        }, 800)
    }
        else {
            window.location.href = '/'
        }
    })
            
    }

}
