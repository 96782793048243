import { module } from 'modujs';
import { html } from '../utils/environment';

export default class extends module {
    constructor(m) {
        super(m)
        this.events ={
          click: {
            open: 'openNav',
            close: 'closeNav'
          }
        }
        
    }

    openNav() {
      if (html.classList.contains('menu-active')) {
        html.classList.remove('menu-active');
      } else {
        html.classList.add('menu-active');
      }
    }
    closeNav() {
      html.classList.remove('menu-active'); 
    }  

}