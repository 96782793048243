import { module } from 'modujs';
import { html } from '../utils/environment';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/all';


gsap.registerPlugin(ScrollTrigger)

export default class extends module {
    constructor(m) {
        super(m);


        this.$dark = this.$("dark")
        this.$light = this.$("light")
        this.$primary = this.$("primary")
        this.$hero = this.$("hero")
    }

    init() {          
          this.$light.forEach(function (section) {
            ScrollTrigger.create({
              trigger: section,
              start: "-1% top",
              end: "bottom top",
              // markers: true,
              onEnter: function () {
                html.classList.add("nav-light");
                // console.log('enter')
              },
              onEnterBack: function () {
                html.classList.add("nav-light");
                // console.log('enterBack')
              },
              onLeave: function () {
                html.classList.remove("nav-light");
                // console.log('leave')
              },
              onLeaveBack: function () {
                html.classList.remove("nav-light");
                // console.log('leaveBack')
              },
            });
          });
          this.$dark.forEach(function (section) {
            ScrollTrigger.create({
              trigger: section,
              start: "-1% top",
              end: "bottom top",
              // markers: true,
              onEnter: function () {
                html.classList.add("nav-dark");
                // console.log('enter')
              },
              onEnterBack: function () {
                html.classList.add("nav-dark");
                // console.log('enterBack')
              },
              onLeave: function () {
                html.classList.remove("nav-dark");
                // console.log('leave')
              },
              onLeaveBack: function () {
                html.classList.remove("nav-dark");
                // console.log('leaveBack')
              },
            });
          });
          this.$primary.forEach(function (section) {
            ScrollTrigger.create({
              trigger: section,
              start: "-1% top",
              end: "bottom top",
              // markers: true,
              onEnter: function () {
                html.classList.add("nav-primary");
                // console.log('enter')
              },
              onEnterBack: function () {
                html.classList.add("nav-primary");
                // console.log('enterBack')
              },
              onLeave: function () {
                html.classList.remove("nav-primary");
                // console.log('leave')
              },
              onLeaveBack: function () {
                html.classList.remove("nav-primary");
                // console.log('leaveBack')
              },
            });
          });
          this.$hero.forEach(function (section) {
            ScrollTrigger.create({
              trigger: section,
              start: "-1% top",
              end: "bottom top",
              // markers: true,
              onEnter: function () {
                html.classList.add("nav-hero");
                // console.log('enter')
              },
              onEnterBack: function () {
                html.classList.add("nav-hero");
                // console.log('enterBack')
              },
              onLeave: function () {
                html.classList.remove("nav-hero");
                // console.log('leave')
              },
              onLeaveBack: function () {
                html.classList.remove("nav-hero");
                // console.log('leaveBack')
              },
            });
          });
    }
}
